import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { independent, independentCookiePolicy, independentLegals } from "config/external-links";
import React from "react";
import { up } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import fluid from "styles/utils/fluid";

const Root = styled.ul`
  margin-top: auto;
  padding: 15px 0;
`;

const ListItem = styled.li`
  display: inline;

  &.first {
    display: block;
    margin-bottom: 10px;
  }
`;

const linkCss = css`
  ${fluid("font-size", {
    base: "12px",
    xs: "14px",
  })}
  line-height: ${em("20px", "14px")};

  text-transform: uppercase;

  ${up("xs")} {
    letter-spacing: ${em("0.15px", "14px")};
  }
`;

const MobileMenuFooter = () => {
  return (
    <Root>
      <ListItem className="first">
        <a className={linkCss} href={independent} target="_blank" rel="noopener noreferrer">
          Independent
        </a>
      </ListItem>
      <ListItem>
        <a
          className={linkCss}
          href={`https://independentbureau.artnav.co/policy/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        &nbsp;&nbsp;
      </ListItem>
      <ListItem>
        <a
          className={linkCss}
          href={`https://independentbureau.artnav.co/policy/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Policy
        </a>
        &nbsp;&nbsp;
      </ListItem>
      <ListItem>
        <a
          className={linkCss}
          href={`https://independentbureau.artnav.co/terms/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
      </ListItem>
    </Root>
  );
};

export default MobileMenuFooter;
