import { styled } from "@linaria/react";
import { fadeClass } from "components/Animations/Fade";
import { Portal } from "components/Portal";
import SearchOverlay from "components/SearchOverlay/SearchOverlay";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import { WithClassNameProps } from "interfaces";
import React from "react";
import { CSSTransition } from "react-transition-group";
import { customProps, zIndex } from "styles";
import HeaderSearchFilters from "./HeaderSearchFilters";

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const DesktopHeaderTitle = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  font-weight: 500;
  font-size: 17px;
  line-height: 1.18;

  text-align: center;
  letter-spacing: 0.377778px;

  color: #000000;
`;

const Input = styled.input`
  display: block;
  width: 335px;
  border: 0;
  font-size: 14px;
  letter-spacing: 1.24px;

  &::-webkit-search-cancel-button {
    display: none;
  }

  &::placeholder {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const SearchResults = styled(SearchOverlay)`
  top: calc(var(${customProps.headerHeight}) + var(${customProps.subHeaderHeight}));
  transition: opacity 100ms ease-in-out;
`;

const Overflow = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.popover};

  width: 100%;
  height: 100%;
  background-color: #d8d8d8;
  opacity: 0.9;
`;

const modalRoot = document.getElementById("modal-root");

const HeaderSearchDesktop = ({ className }: WithClassNameProps) => {
  const {
    handleSearchChange,
    searchOverlayState: { searchString, showResults },
    // deepSearch,
    closeSearch,
  } = useShallowSearch();

  // const handleEnter = useHandleKey("Enter", deepSearch);
  return (
    <>
      <SearchWrapper className={className}>
        <Input
          placeholder="Search for Galleries"
          onChange={handleSearchChange}
          // onKeyUp={handleEnter}
          value={searchString}
          type="search"
          autoFocus
        />
        {/* <HeaderSearchFilters /> */}
        <CSSTransition mountOnEnter unmountOnExit timeout={200} in={showResults} classNames={fadeClass}>
          <SearchResults />
        </CSSTransition>
      </SearchWrapper>
      <Portal parentEl={modalRoot!}>
        <Overflow aria-label="close-search" onClick={closeSearch} />
      </Portal>
    </>
  );
};

export default HeaderSearchDesktop;
