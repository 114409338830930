import * as React from "react";

const SvgIndependentLogoNext = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 240" {...props}>
    <g>
      <path
        style={{ fill: "#ffffff" }}
        d="M32.6,51.5c0.1,1.1,0.2,2.2,0.2,3.3c0,15.8,0,31.6,0,47.4c0,0.9-0.1,1.8-0.2,2.7c0,0.2,0,0.4-0.1,0.6
		c3.8,0,7.6,0,11.4,0c0-0.2,0-0.3,0-0.4c-0.1-0.9-0.2-1.7-0.2-2.6c0-8.8,0-17.5,0-26.3c0-0.1,0-0.3,0-0.4c0,0,0.1,0,0.1,0
		c2.5,9.9,5.1,19.8,7.6,29.7c1.2,0.1,11.7,0.1,12.2-0.1c0-0.3,0-0.6,0-0.9c0-16.5,0-33.1,0-49.6c0-0.7,0.1-1.4,0.1-2
		c0-0.4,0.1-0.8,0.2-1.3c-3.8,0-7.6,0-11.4,0c0,0.2,0,0.3,0,0.4c0.1,0.9,0.2,1.8,0.2,2.7c0,6.4,0,12.9,0,19.3c0,0.2,0,0.4,0,0.7
		c0,0-0.1,0-0.1,0c-1.9-7.7-3.8-15.4-5.7-23.1C42,51.5,37.3,51.5,32.6,51.5z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M189.4,105.4c3.9,0,7.7,0,11.5,0c0-0.2,0-0.4-0.1-0.5c-0.1-0.8-0.2-1.7-0.2-2.5c0-8.7,0-17.3,0-26
		c0-0.2,0-0.4,0-0.7c0,0,0.1,0,0.1,0c2.5,9.9,5.1,19.8,7.6,29.7c4.1,0,8.1,0,12.2,0c0-0.3,0-0.7,0-1c0-16.5,0-33.1,0-49.6
		c0-0.8,0.1-1.6,0.2-2.4c0-0.3,0.1-0.6,0.1-0.9c-0.8-0.1-10.9-0.1-11.4,0c0.1,1,0.2,2,0.2,3c0,6.5,0,12.9,0,19.4c0,0.2,0,0.5,0,0.7
		c0,0-0.1,0-0.1,0c-1.9-7.7-3.8-15.4-5.7-23.1c-4.8,0-9.6,0-14.4,0c0.5,2.9,0.3,5.9,0.3,8.8c0,13.3,0,26.5,0,39.8
		C189.7,101.8,189.8,103.6,189.4,105.4z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M299.7,51.5c-2.2-0.1-13.8-0.1-14.4,0.1c0.4,1.8,0.3,3.5,0.3,5.3c0,14.9,0,29.8,0,44.7c0,0.7,0,1.3-0.1,2
		c0,0.6-0.1,1.2-0.2,1.8c3.8,0,7.6,0,11.4,0c-0.1-1-0.3-2-0.3-3c0-8.7,0-17.5,0-26.2c0-0.2,0-0.3,0-0.5c0,0,0.1,0,0.1,0
		c2.5,9.9,5,19.8,7.6,29.7c4.1,0,8.1,0,12.2,0c0-0.4,0-0.7,0-1c0-16.5,0-32.9,0-49.4c0-0.8,0.1-1.6,0.1-2.3c0-0.4,0.1-0.8,0.2-1.2
		c-1-0.1-11-0.1-11.4,0c0.1,1.2,0.2,2.4,0.2,3.5c0,6.3,0,12.7,0,19c0,0.2,0,0.4,0,0.6c-0.1,0-0.1,0-0.2,0
		C303.5,66.9,301.6,59.2,299.7,51.5z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M47.6,109.8c0,0.1,0,0.3,0,0.4c0.1,1.2,0.3,2.4,0.3,3.7c0,13,0,26,0,39c0,1.2,0.1,2.4,0.3,3.7
		c0.7,3.4,2.6,5.7,5.8,6.9c1.2,0.4,2.5,0.7,3.8,0.9c2.6,0.3,5.2,0.3,7.8,0c1.5-0.2,2.9-0.5,4.3-1.1c2.4-1,3.9-2.8,4.6-5.3
		c0.4-1.4,0.5-2.8,0.5-4.2c0-12.7,0-25.3,0-38c0-1.9-0.1-3.9,0.3-5.8c-0.6-0.2-8.8-0.2-9.3,0c0.5,2.7,0.3,5.4,0.3,8.1
		c0,8.6,0,17.2,0,25.9c0,3.3,0,6.6,0,9.9c0,0.7-0.1,1.4-0.3,2c-0.3,1.1-1,1.5-2.1,1.6c-1.2,0-2-0.5-2.2-1.7
		c-0.2-0.8-0.3-1.7-0.3-2.6c0-12.4,0-24.8,0-37.2c0-2-0.1-4,0.3-6.1C57,109.8,52.3,109.8,47.6,109.8z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M197.8,109.8c-3.1,0-6.2,0-9.3,0c0,0.3,0.1,0.5,0.1,0.7c0.1,0.8,0.2,1.6,0.2,2.4c0,13.5,0,27,0,40.6
		c0,0.7-0.1,1.5-0.3,2.2c-0.3,1.1-1,1.6-2.2,1.7c-1.1,0-1.9-0.5-2.2-1.6c-0.2-0.7-0.3-1.4-0.3-2.1c0-13.6,0-27.2,0-40.8
		c0-0.4,0-0.9,0.1-1.3c0.1-0.5,0.1-1.1,0.2-1.6c-4.7,0-9.4,0-14.1,0c0,0.1,0,0.2,0,0.2c0.3,1.2,0.3,2.4,0.3,3.7c0,13,0,26,0,39
		c0,1.3,0.1,2.7,0.4,4c0.7,3.3,2.6,5.5,5.8,6.7c1.2,0.4,2.5,0.7,3.8,0.9c2.6,0.3,5.2,0.3,7.8,0c1.6-0.2,3.2-0.5,4.7-1.3
		c2.1-1,3.5-2.6,4.1-4.7c0.4-1.4,0.6-2.8,0.6-4.3c0-13.4,0-26.9,0-40.3c0-0.8,0.1-1.7,0.1-2.5C197.7,110.7,197.8,110.3,197.8,109.8z
		"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M174.6,98.8c0-5.9,0-11.6,0-17.4c3,0.1,5.9-0.1,8.9,0.2c0-2.3,0-4.6,0-6.8c-3,0.3-5.9,0.1-8.9,0.2
		c0-5.7,0-11.3,0-16.9c0.3,0,0.6,0,0.8,0c2.5,0,5,0,7.6,0c1.5,0,2.9,0,4.4,0.3c0-2.3,0-4.6,0-6.9c-8.9,0-17.8,0-26.6,0
		c0.1,1,0.2,2,0.2,2.9c0,16,0,32,0,48.1c0,0.8-0.1,1.6-0.2,2.5c0,0.2,0,0.3-0.1,0.5c8.9,0,17.8,0,26.7,0c0-2.3,0-4.6,0-6.9
		c-2.1,0.4-4.3,0.3-6.4,0.3C178.9,98.8,176.8,98.8,174.6,98.8z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M99.7,51.5c0.5,2.5,0.3,5,0.3,7.4c0,13,0,26.1,0,39.1c0,2.5,0.2,4.9-0.3,7.4c1.1,0.1,26.2,0.1,26.7,0
		c0.1-1.1,0.1-6.2-0.1-6.8c-2.1,0.4-4.2,0.3-6.3,0.3c-2.1,0-4.2,0-6.3,0c-0.2-0.7-0.2-16.9,0-17.5c2.9,0,5.8,0.1,8.8,0.1
		c0-2.2,0-4.5,0-6.7c-3,0.3-5.9,0.1-8.8,0.2c-0.2-1-0.1-16.4,0-16.9c0.1,0,0.2,0,0.3,0c4.1,0.1,8.3-0.2,12.4,0.2c0-2.2,0-4.5,0-6.8
		C117.5,51.5,108.6,51.5,99.7,51.5z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M256.6,51.4c0.5,3,0.3,5.9,0.3,8.8c0,11,0,22.1,0,33.1c0,4,0.3,8-0.3,12c8.9,0,17.7,0,26.6,0
		c0.2-0.8,0.1-6.3,0-6.8c-4.2,0.5-8.5,0.1-12.7,0.3c0-5.9,0-11.6,0-17.4c3,0,5.9-0.1,8.9,0.2c0-2.3,0-4.6,0-6.8
		c-3,0.3-5.9,0.1-8.8,0.2c-0.2-0.9-0.1-16.3,0-16.9c0.2,0,0.3,0,0.5,0c3.1,0,6.3,0,9.4,0c0.8,0,1.6,0.1,2.4,0.2c0.2,0,0.3,0,0.5,0
		c0-2.3,0-4.6,0-6.8C274.4,51.4,265.6,51.4,256.6,51.4z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M110.4,109.8c0,0.1,0,0.2,0,0.3c0.2,1.1,0.3,2.2,0.3,3.3c0,15.7,0,31.3,0,47c0,0.8-0.1,1.6-0.2,2.5
		c0,0.3-0.1,0.5-0.1,0.8c8.9,0,17.8,0,26.7,0c0-2.3,0-4.5,0-6.8c-0.2,0-0.4,0-0.5,0c-0.9,0.1-1.9,0.2-2.8,0.2c-3,0-5.9,0-8.9,0
		c-0.2,0-0.4,0-0.5-0.1c-0.1-1.3-0.1-16.8,0-17.3c2.9,0,5.9,0,8.8,0c0-2.2,0-4.5,0-6.8c-3,0.3-6,0.2-8.9,0.2c0-5.7,0-11.3,0-16.9
		c0.3,0,0.6,0,0.8,0c2.9,0,5.7,0,8.6,0c0.8,0,1.6,0.1,2.4,0.1c0.3,0,0.6,0.1,1,0.2c0-2.3,0-4.5,0-6.8
		C128.2,109.8,119.3,109.8,110.4,109.8z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M341.3,105.4c-0.1-1.1-0.2-2-0.2-3c0-14.6,0-29.1,0-43.7c0-0.2,0-0.4,0.1-0.7c3,0.1,6.1-0.2,9.1,0.3
		c0-2.3,0-4.6,0-6.8c-1.1-0.1-31.3-0.1-31.8,0c0,2.3,0,4.5,0,6.8c2.2-0.3,8.3-0.4,9.1-0.1c0,0.2,0,0.5,0,0.8c0,14.3,0,28.6,0,42.9
		c0,0.7,0,1.3-0.1,2c0,0.5-0.1,1.1-0.2,1.6C331.9,105.4,336.6,105.4,341.3,105.4z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M15.5,51.4c0.5,2.1,0.3,4.1,0.3,6.2c0,12.2,0,24.5,0,36.7c0,3.7,0.3,7.3-0.3,11c4.7,0,9.4,0,14.2,0
		c-0.5-2.2-0.3-4.4-0.3-6.6c0-14.6,0-29.3,0-43.9c0-0.6,0-1.1,0.1-1.7c0-0.6,0.1-1.1,0.2-1.7C25,51.4,20.3,51.4,15.5,51.4z"
      />
      <path
        d="M32.6,51.5c4.7,0,9.5,0,14.3,0c1.9,7.7,3.8,15.4,5.7,23.1c0,0,0.1,0,0.1,0c0-0.2,0-0.4,0-0.7c0-6.4,0-12.9,0-19.3
		c0-0.9-0.2-1.8-0.2-2.7c0-0.1,0-0.2,0-0.4c3.8,0,7.6,0,11.4,0c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.7-0.1,1.4-0.1,2
		c0,16.5,0,33.1,0,49.6c0,0.3,0,0.6,0,0.9c-0.5,0.2-11,0.2-12.2,0.1c-2.5-9.9-5-19.8-7.6-29.7c0,0-0.1,0-0.1,0c0,0.1,0,0.3,0,0.4
		c0,8.8,0,17.5,0,26.3c0,0.9,0.2,1.7,0.2,2.6c0,0.1,0,0.3,0,0.4c-3.8,0-7.6,0-11.4,0c0-0.2,0.1-0.4,0.1-0.6c0.1-0.9,0.2-1.8,0.2-2.7
		c0-15.8,0-31.6,0-47.4C32.8,53.6,32.7,52.6,32.6,51.5z"
      />
      <path
        d="M189.4,105.4c0.4-1.8,0.3-3.6,0.3-5.3c0-13.3,0-26.5,0-39.8c0-2.9,0.2-5.8-0.3-8.8c4.8,0,9.5,0,14.4,0
		c1.9,7.7,3.8,15.4,5.7,23.1c0,0,0.1,0,0.1,0c0-0.2,0-0.5,0-0.7c0-6.5,0-12.9,0-19.4c0-1-0.1-2-0.2-3c0.4-0.1,10.5-0.2,11.4,0
		c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.8-0.2,1.6-0.2,2.4c0,16.5,0,33.1,0,49.6c0,0.3,0,0.6,0,1c-4.1,0-8.1,0-12.2,0
		c-2.5-9.9-5-19.8-7.6-29.7c0,0-0.1,0-0.1,0c0,0.2,0,0.4,0,0.7c0,8.7,0,17.3,0,26c0,0.8,0.1,1.7,0.2,2.5c0,0.2,0,0.3,0.1,0.5
		C197.1,105.4,193.3,105.4,189.4,105.4z"
      />
      <path
        d="M299.7,51.5c1.9,7.7,3.8,15.5,5.8,23.2c0.1,0,0.1,0,0.2,0c0-0.2,0-0.4,0-0.6c0-6.3,0-12.7,0-19c0-1.2-0.2-2.3-0.2-3.5
		c0.4-0.1,10.4-0.2,11.4,0c-0.1,0.4-0.2,0.8-0.2,1.2c-0.1,0.8-0.1,1.6-0.1,2.3c0,16.5,0,32.9,0,49.4c0,0.3,0,0.6,0,1
		c-4.1,0-8.1,0-12.2,0c-2.5-9.9-5-19.8-7.6-29.7c0,0-0.1,0-0.1,0c0,0.2,0,0.3,0,0.5c0,8.7,0,17.5,0,26.2c0,1,0.2,2,0.3,3
		c-3.8,0-7.6,0-11.4,0c0.1-0.6,0.2-1.2,0.2-1.8c0.1-0.7,0.1-1.3,0.1-2c0-14.9,0-29.8,0-44.7c0-1.8,0.1-3.6-0.3-5.3
		C285.9,51.4,297.5,51.3,299.7,51.5z"
      />
      <path
        d="M223.6,51.4c0.3,0,0.5,0,0.8,0c5.1,0,10.1,0,15.2,0c2.5,0,4.9,0.3,7.3,1.1c1.1,0.4,2.2,0.9,3.2,1.7c2,1.5,3.2,3.6,3.6,6.1
		c0.2,0.9,0.2,1.8,0.3,2.7c0,10.3,0,20.5,0,30.8c0,1.2-0.1,2.4-0.4,3.6c-0.8,2.9-2.5,5-5.2,6.3c-1.9,0.9-3.9,1.4-5.9,1.5
		c-1.3,0.1-2.6,0.1-3.9,0.2c-4.7,0-9.4,0-14.1,0c-0.3,0-0.5,0-0.8,0c0.1-0.5,0.1-0.9,0.2-1.4c0.1-0.7,0.1-1.4,0.1-2
		c0-15.7,0-31.5,0-47.2c0-1-0.2-1.9-0.2-2.9C223.6,51.8,223.6,51.6,223.6,51.4z M237.5,57.7c0,13.8,0,27.5,0,41.4c0.5,0,1,0,1.6,0
		c0.8,0,1.2-0.4,1.3-1.2c0-0.2,0-0.5,0-0.7c0-2.5,0-5,0-7.6c0-10,0-20,0-30c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.4-0.7-0.9-0.7
		C238.8,57.7,238.2,57.7,237.5,57.7z"
      />
      <path
        d="M66.7,51.5c0.2,0,0.3-0.1,0.4-0.1c5.4,0,10.8,0,16.3,0c2.2,0,4.5,0.3,6.6,1.1c1.2,0.4,2.3,1,3.3,1.8c2,1.6,3.1,3.6,3.5,6.1
		c0.2,0.9,0.2,1.9,0.2,2.9c0,10.1,0,20.3,0,30.4c0,1.5-0.2,3.1-0.7,4.5c-0.9,2.6-2.6,4.4-5,5.6c-1.9,0.9-3.9,1.4-5.9,1.5
		c-1.3,0.1-2.6,0.1-3.9,0.1c-4.7,0-9.4,0-14.1,0c-0.3,0-0.5,0-0.8,0c0.1-0.5,0.1-1,0.2-1.4c0.1-0.6,0.1-1.3,0.1-1.9
		c0-15.8,0-31.5,0-47.3c0-0.9-0.2-1.9-0.2-2.8C66.7,51.8,66.7,51.7,66.7,51.5z M80.6,57.7c0,13.9,0,27.6,0,41.4c0.6,0,1.2,0,1.8,0
		c0.5,0,0.8-0.3,1-0.8c0.1-0.3,0.1-0.6,0.1-0.9c0-0.8,0-1.6,0-2.5c0-11.7,0-23.5,0-35.2c0-0.4,0-0.8-0.1-1.3
		c-0.1-0.4-0.4-0.7-0.9-0.7C81.9,57.7,81.3,57.7,80.6,57.7z"
      />
      <path
        d="M15.5,163.8c0.1-1.4,0.2-2.8,0.2-4.1c0-14,0-27.9,0-41.9c0-2.7,0.2-5.3-0.2-8c0.2,0,0.3-0.1,0.5-0.1c5.5,0,11,0,16.5,0.1
		c2.2,0,4.4,0.5,6.5,1.4c3.9,1.7,6,4.7,6.2,8.8c0.2,2.6,0.1,5.2,0,7.8c-0.1,3-1.5,5.4-4.3,6.8c-0.1,0.1-0.2,0.1-0.3,0.2
		c0,0,0,0,0,0.1c0.1,0.1,0.2,0.1,0.3,0.2c2.5,1.2,3.9,3.3,4.4,6c0.2,1,0.3,2,0.3,2.9c0,2.8,0,5.6,0,8.4c0,1.7-0.3,3.4-1,5
		c-1.1,2.4-2.9,3.9-5.3,4.9c-1.7,0.7-3.4,1.1-5.2,1.3c-1.3,0.1-2.6,0.2-3.9,0.2c-4.6,0-9.3,0-13.9,0
		C16.1,163.8,15.8,163.8,15.5,163.8z M29.3,157.7c0.5,0,0.9,0,1.3,0c0.8,0,1.3-0.4,1.3-1.2c0-0.2,0-0.4,0-0.6c0-5.2,0-10.3,0-15.5
		c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.4-0.4-0.6-0.8-0.7c-0.5,0-1.1,0-1.6,0C29.3,145,29.3,151.3,29.3,157.7z M29.3,132.9
		c0.3,0,0.5,0,0.7,0c1.2,0.1,1.6-0.6,1.6-1.5c0-0.1,0-0.2,0-0.3c0-4.5,0-9,0-13.5c0-0.3,0-0.6-0.1-0.9c-0.1-0.4-0.3-0.8-0.8-0.8
		c-0.5-0.1-1-0.1-1.5,0C29.3,121.5,29.3,127.2,29.3,132.9z"
      />
      <path
        d="M77.7,163.8c0.1-1.4,0.2-2.7,0.2-4c0-15,0-30,0-45c0-1.6,0.1-3.3-0.3-5c0.2,0,0.4-0.1,0.5-0.1c5.6,0,11.2,0,16.8,0.1
		c2.3,0,4.5,0.5,6.7,1.4c3.6,1.6,5.6,4.3,6.1,8.2c0.1,0.7,0.1,1.4,0.1,2c0,3.6,0,7.1,0,10.7c0,1.6-0.2,3.1-0.7,4.6
		c-0.7,1.8-1.8,3.3-3.4,4.5c-0.1,0.1-0.2,0.2-0.4,0.3c0.2,1.1,0.5,2.1,0.7,3.2c1.1,4.8,2.2,9.7,3.2,14.5c0.3,1.5,0.8,3.1,1.6,4.5
		c-4.6,0-9.1,0-13.7,0c-1.1-6.6-2.1-13.2-3.2-19.9c-0.2,0-0.3,0-0.6,0c0,0.3,0,0.5,0,0.8c0,5.3,0,10.5,0,15.8c0,1.1,0.1,2.2,0.2,3.3
		C87,163.8,82.4,163.8,77.7,163.8z M91.5,115.8c0,7.3,0,14.6,0,21.9c0.5,0,0.9,0,1.4,0c0.7,0,1.1-0.3,1.2-1c0.1-0.3,0-0.5,0-0.8
		c0-6.1,0-12.2,0-18.4c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.4-0.4-0.6-0.8-0.7C92.6,115.8,92.1,115.8,91.5,115.8z"
      />
      <path
        d="M47.6,109.8c4.7,0,9.4,0,14.2,0c-0.4,2.1-0.3,4.1-0.3,6.1c0,12.4,0,24.8,0,37.2c0,0.9,0.1,1.7,0.3,2.6
		c0.3,1.2,1.1,1.7,2.2,1.7c1.1,0,1.8-0.5,2.1-1.6c0.2-0.6,0.3-1.3,0.3-2c0-3.3,0-6.6,0-9.9c0-8.6,0-17.2,0-25.9
		c0-2.7,0.2-5.4-0.3-8.1c0.6-0.2,8.7-0.2,9.3,0c-0.4,1.9-0.3,3.8-0.3,5.8c0,12.7,0,25.3,0,38c0,1.4-0.1,2.8-0.5,4.2
		c-0.7,2.5-2.2,4.3-4.6,5.3c-1.4,0.6-2.8,0.9-4.3,1.1c-2.6,0.3-5.2,0.3-7.8,0c-1.3-0.1-2.6-0.4-3.8-0.9c-3.2-1.2-5.1-3.5-5.8-6.9
		c-0.2-1.2-0.3-2.4-0.3-3.7c0-13,0-26,0-39c0-1.2-0.2-2.4-0.3-3.7C47.6,110,47.6,109.9,47.6,109.8z"
      />
      <path
        d="M197.8,109.8c-0.1,0.5-0.2,0.9-0.2,1.4c-0.1,0.8-0.1,1.7-0.1,2.5c0,13.4,0,26.9,0,40.3c0,1.4-0.1,2.9-0.6,4.3
		c-0.7,2.2-2.1,3.8-4.1,4.7c-1.5,0.7-3.1,1.1-4.7,1.3c-2.6,0.3-5.2,0.3-7.8,0c-1.3-0.1-2.6-0.4-3.8-0.9c-3.2-1.2-5.1-3.4-5.8-6.7
		c-0.3-1.3-0.4-2.7-0.4-4c0-13,0-26,0-39c0-1.2,0-2.4-0.3-3.7c0-0.1,0-0.1,0-0.2c4.7,0,9.4,0,14.1,0c-0.1,0.6-0.1,1.1-0.2,1.6
		c0,0.4-0.1,0.9-0.1,1.3c0,13.6,0,27.2,0,40.8c0,0.7,0.1,1.4,0.3,2.1c0.3,1.1,1.1,1.6,2.2,1.6c1.1,0,1.9-0.5,2.2-1.7
		c0.2-0.7,0.3-1.4,0.3-2.2c0-13.5,0-27,0-40.6c0-0.8-0.1-1.6-0.2-2.4c0-0.2-0.1-0.5-0.1-0.7C191.6,109.8,194.7,109.8,197.8,109.8z"
      />
      <path
        d="M128.4,51.5c0.2,0,0.3,0,0.4,0c5.4,0,10.7,0,16.1,0c2.4,0,4.7,0.3,6.9,1.2c4.3,1.6,6.5,4.8,6.8,9.4c0.1,1.9,0,3.8,0,5.7
		c0,2.9,0,5.7,0,8.6c0,1.4-0.2,2.8-0.7,4.1c-0.9,2.6-2.6,4.4-5,5.6c-1.8,0.9-3.8,1.4-5.8,1.5c-1.4,0.1-2.7,0.1-4.1,0.2
		c-0.2,0-0.4,0-0.7,0c0,0.3-0.1,0.5-0.1,0.7c0,4.8,0,9.5,0,14.3c0,0.9,0.2,1.9,0.2,2.8c-4.7,0-9.4,0-14.1,0
		c0.5-3.6,0.3-7.2,0.3-10.8c0-12.1,0-24.2,0-36.3C128.7,56,128.9,53.7,128.4,51.5z M142.3,81.3c0.4,0,0.8,0,1.1,0
		c1,0.1,1.6-0.3,1.7-1.6c0-0.1,0-0.2,0-0.3c0-6.6,0-13.3,0-19.9c0-0.3,0-0.6-0.1-0.8c-0.1-0.5-0.4-0.9-0.9-0.9c-0.6-0.1-1.2,0-1.8,0
		C142.3,65.6,142.3,73.4,142.3,81.3z"
      />
      <path
        d="M154.8,150.8c-1.7,0-3.3,0-5,0c-0.3,0.8-1.2,11.2-1.2,12.8c-0.5,0.1-9.1,0.2-10.3,0.1c0.1-0.5,0.3-0.9,0.3-1.3
		c0.3-1.9,0.6-3.7,0.8-5.6c0.5-3.3,0.9-6.5,1.4-9.8c0.4-3.2,0.9-6.4,1.3-9.6c0.5-3.3,0.9-6.6,1.4-9.9c0.4-3.2,0.9-6.4,1.3-9.7
		c0.3-1.8,0.5-3.7,0.7-5.5c0.1-0.6,0.1-1.2,0.1-1.8c0-0.3,0-0.5,0-0.8c5.4,0,10.8,0,16.1,0c0,2.5,0.5,4.8,0.9,7.2
		c0.5,3.3,1,6.6,1.5,9.8c0.5,3.6,1.1,7.2,1.6,10.7c0.5,3.6,1.1,7.1,1.6,10.7c0.6,3.9,1.2,7.8,1.8,11.7c0.2,1.3,0.5,2.5,0.7,3.8
		c-4.7,0-9.5,0-14.3,0c0.2-2.3-0.3-4.4-0.4-6.6c-0.1-1.6-0.2-3.3-0.4-4.9C154.9,151.7,154.8,151.3,154.8,150.8z M152.7,125.7
		c-0.9,6.3-1.6,12.6-2.3,18.9c1.4,0,2.6,0,4,0C153.7,138.3,153.2,132,152.7,125.7z"
      />
      <path
        d="M174.6,98.8c2.1,0,4.3,0,6.4,0c2.1,0,4.2,0.1,6.4-0.3c0,2.3,0,4.6,0,6.9c-8.9,0-17.8,0-26.7,0c0-0.2,0-0.3,0.1-0.5
		c0.1-0.8,0.2-1.6,0.2-2.5c0-16,0-32,0-48.1c0-1-0.1-1.9-0.2-2.9c8.8,0,17.7,0,26.6,0c0,2.3,0,4.6,0,6.9c-1.5-0.4-2.9-0.3-4.4-0.3
		c-2.5,0-5,0-7.6,0c-0.3,0-0.5,0-0.8,0c0,5.6,0,11.2,0,16.9c3,0,5.9,0.1,8.9-0.2c0,2.3,0,4.5,0,6.8c-3-0.3-5.9-0.1-8.9-0.2
		C174.6,87.2,174.6,93,174.6,98.8z"
      />
      <path
        d="M99.7,51.5c8.9,0,17.8,0,26.7,0c0,2.3,0,4.5,0,6.8c-4.2-0.5-8.3-0.1-12.4-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0.5-0.2,15.9,0,16.9
		c2.9,0,5.8,0.1,8.8-0.2c0,2.3,0,4.5,0,6.7c-2.9,0-5.9-0.1-8.8-0.1c-0.2,0.6-0.2,16.8,0,17.5c2.1,0,4.2,0,6.3,0
		c2.1,0,4.2,0.1,6.3-0.3c0.2,0.5,0.2,5.6,0.1,6.8c-0.5,0.1-25.6,0.2-26.7,0c0.5-2.4,0.3-4.9,0.3-7.4c0-13,0-26.1,0-39.1
		C100,56.4,100.2,53.9,99.7,51.5z"
      />
      <path
        d="M256.6,51.4c8.9,0,17.8,0,26.7,0c0,2.3,0,4.5,0,6.8c-0.2,0-0.3,0-0.5,0c-0.8-0.1-1.6-0.2-2.4-0.2c-3.1,0-6.3,0-9.4,0
		c-0.2,0-0.3,0-0.5,0c-0.2,0.5-0.2,16,0,16.9c2.9,0,5.8,0.1,8.8-0.2c0,2.3,0,4.6,0,6.8c-3-0.3-5.9-0.2-8.9-0.2c0,5.8,0,11.6,0,17.4
		c4.3-0.1,8.5,0.2,12.7-0.3c0.1,0.5,0.2,6,0,6.8c-8.9,0-17.7,0-26.6,0c0.5-4,0.3-8,0.3-12c0-11,0-22.1,0-33.1
		C256.9,57.3,257.2,54.4,256.6,51.4z"
      />
      <path
        d="M110.4,109.8c8.9,0,17.8,0,26.7,0c0,2.3,0,4.5,0,6.8c-0.4-0.1-0.7-0.1-1-0.2c-0.8-0.1-1.6-0.1-2.4-0.1c-2.9,0-5.7,0-8.6,0
		c-0.3,0-0.5,0-0.8,0c0,5.6,0,11.2,0,16.9c2.9,0,5.9,0.1,8.9-0.2c0,2.3,0,4.5,0,6.8c-3,0-5.9,0-8.8,0c-0.1,0.5-0.2,16,0,17.3
		c0.2,0,0.3,0.1,0.5,0.1c3,0,5.9,0,8.9,0c0.9,0,1.9-0.2,2.8-0.2c0.2,0,0.3,0,0.5,0c0,2.3,0,4.5,0,6.8c-8.9,0-17.8,0-26.7,0
		c0-0.3,0.1-0.5,0.1-0.8c0.1-0.8,0.2-1.6,0.2-2.5c0-15.7,0-31.3,0-47c0-1.1,0-2.2-0.3-3.3C110.4,110,110.4,109.9,110.4,109.8z"
      />
      <path
        d="M341.3,105.4c-4.7,0-9.4,0-14.1,0c0.1-0.6,0.2-1.1,0.2-1.6c0.1-0.7,0.1-1.3,0.1-2c0-14.3,0-28.6,0-42.9c0-0.3,0-0.5,0-0.8
		c-0.8-0.3-7-0.1-9.1,0.1c0-2.3,0-4.5,0-6.8c0.5-0.1,30.7-0.2,31.8,0c0,2.3,0,4.5,0,6.8c-3-0.5-6-0.2-9.1-0.3c0,0.3-0.1,0.5-0.1,0.7
		c0,14.6,0,29.1,0,43.7C341.1,103.3,341.2,104.3,341.3,105.4z"
      />
      <path
        d="M15.5,51.4c4.8,0,9.5,0,14.2,0c-0.1,0.6-0.2,1.1-0.2,1.7c0,0.6-0.1,1.1-0.1,1.7c0,14.6,0,29.3,0,43.9
		c0,2.2-0.2,4.4,0.3,6.6c-4.7,0-9.4,0-14.2,0c0.5-3.7,0.2-7.3,0.3-11c0-12.2,0-24.5,0-36.7C15.9,55.6,16,53.5,15.5,51.4z"
      />
    </g>
  </svg>
);
export default SvgIndependentLogoNext;
