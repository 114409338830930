import { styled } from "@linaria/react";
import { spacerMixin } from "components/BaseSpacer";
import {
  independent,
  independentCookiePolicy,
  independentLegals,
  independentPrivacyPolicy,
} from "config/external-links";
import React from "react";
import { colors } from "styles";
import { down, up } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import responsive from "styles/utils/responsive";

type FooterProps = {
  className?: string;
};

const FooterRoot = styled.footer`
  z-index: 10;
  position: relative;

  background-color: #ffffff;

  .dark & {
    background-color: ${colors.dark};
  }

  ${down("lg")} {
    &:not(.disableMargin) {
      margin-top: 40px;
    }
  }
`;

const List = styled.ul`
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  min-height: 80px;

  ${spacerMixin}

  border-top: solid 1px rgb(0, 0, 0);
  .dark & {
    border-top-color: rgb(255, 255, 255, 0.2);
  }

  ${up("lg")} {
    padding-top: 0;

    align-items: center;
    border-top: solid 1px rgba(0, 0, 0, 0.29);

    .dark & {
      border-top-color: rgba(255, 255, 255, 0.29);
    }
  }
`;

const Text = styled.p`
  flex-shrink: 0;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: ${em("0.26px", "12px")};
  text-transform: uppercase;

  ${up("lg")} {
    font-size: 12px;
    line-height: 20px;

    letter-spacing: 0.280159px;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const ListItem = styled.li`
  display: none;
  flex-shrink: 0;
  &:first-child {
    display: initial;
  }

  & + & {
    ${responsive("margin-left", {
      base: "20px",
    })}
  }

  &.db {
    display: initial;
    margin-left: auto;
  }

  ${up("lg")} {
    display: initial;
    &.db {
      display: none;
    }
  }
`;

const year = new Date().getFullYear();

const Footer = ({ className }: FooterProps) => {
  return (
    <FooterRoot className={className}>
      <List className="list">
        <ListItem>
          <Text>© Independent {year}</Text>
        </ListItem>
        <ListItem>
          <Link href={`https://independentbureau.artnav.co/policy/`} target="_blank" rel="noopener noreferrer">
            <Text>Privacy Policy</Text>
          </Link>
        </ListItem>
        <ListItem>
          <Link href={`https://independentbureau.artnav.co/policy/`} target="_blank" rel="noopener noreferrer">
            <Text>Cookie Policy</Text>
          </Link>
        </ListItem>
        <ListItem>
          <Link href={`https://independentbureau.artnav.co/terms/`} target="_blank" rel="noopener noreferrer">
            <Text>Terms & Conditions</Text>
          </Link>
        </ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
      </List>
    </FooterRoot>
  );
};

export default Footer;
