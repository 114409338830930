import RootThemedLayout from "components/RootThemedLayout";
import FairContextNavigation from "features/ContextNavigation/FairContextNavigation/FairContextNavigation";
import { setNavigation } from "features/ContextNavigation/store/contextNavigationSlice";
import HeaderSwitch from "features/Header/HeaderSwitch";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";

const FairViewDesktop = React.lazy(() => import("./FairViewDesktop"));
const FairViewMobile = React.lazy(() => import("./FairViewMobile"));

const HomeView = () => {
  const upLg = useBreakpoint(up("lg"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setNavigation({
        ids: [],
        title: "",
        section: "viewingRoom",
      })
    );
  }, [dispatch]);

  return (
    <RootThemedLayout>
      <HeaderSwitch />
      <FairContextNavigation />
      {upLg ? <FairViewDesktop /> : <FairViewMobile />}
    </RootThemedLayout>
  );
};

export default HomeView;
