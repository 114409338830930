import { styled } from "@linaria/react";
import clsx from "clsx";
import "components/Animations/Fade";
import SearchOverlay from "components/SearchOverlay/SearchOverlay";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import { searchRoute } from "features/Search/search.routes";
import SearchFiltersMenuMobile, {
  SearchFiltersMenuMobileProps,
} from "features/Search/SearchFiltersMobile/SearchFiltersMenuMobile";
import SearchFiltersMobile from "features/Search/SearchFiltersMobile/SearchFiltersMobile";
import useHandleKey from "hooks/useHandleKey";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import em from "styles/utils/em";
import SubheaderBase from "../SubheaderBase/SubheaderBase";

const Input = styled.input`
  height: 100%;
  width: 100%;
  border: 0;
  padding: 0 20px;
  font-size: 14px;
  letter-spacing: ${em("0.16px", "14px")};
  &::placeholder {
    font-size: 13px;
    text-transform: uppercase;
  }
`;

const Root = styled.div`
  position: absolute;
  top: 100%;
  overflow: auto;
  left: 0;
  right: 0;
  transition: opacity 200ms ease-in-out;
  transition-property: background-color, opacity;

  &.overlay,
  &.results {
    height: calc(100vh - var(--header-height));
  }
  &.overlay {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &.results {
    background-color: #ffffff;
  }
`;

const SearchFilters = styled(SearchFiltersMobile)`
  & {
    display: flex;
    flex-direction: column;

    max-height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--subheader-height));
  }
  & .button,
  & .filters {
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
  }
  & .button {
    flex-shrink: 0;
    flex-grow: 0;
  }
  & .filters {
    flex-grow: 1;
    flex-shrink: 1;
    position: static;
    overflow-y: auto;
  }
`;

const SearchFiltersForm = (props: SearchFiltersMenuMobileProps) => {
  return <SearchFiltersMenuMobile {...props} mode="header" />;
};

const SearchOverlayMobile = () => {
  const {
    handleSearchChange,
    searchOverlayState: { active, searchString, showResults },
    deepSearch,
  } = useShallowSearch();

  const match = useRouteMatch({ path: searchRoute.path, exact: false });
  const handleEnter = useHandleKey("Enter", deepSearch);

  return (
    <CSSTransition mountOnEnter unmountOnExit classNames="fade-in" in={active || !!match} timeout={200}>
      <Root
        className={clsx({
          overlay: !match,
          results: showResults,
        })}
      >
        <SubheaderBase>
          <Input
            value={searchString}
            onKeyDown={handleEnter}
            autoFocus
            onChange={handleSearchChange}
            placeholder="Search for Galleries"
          />
        </SubheaderBase>
        {!match && <SearchFilters FiltersForm={SearchFiltersForm} />}

        {showResults && <SearchOverlay />}
      </Root>
    </CSSTransition>
  );
};

export default SearchOverlayMobile;
